var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline"}},[_c('b-button',{staticClass:"btn-icon mr-2",attrs:{"size":"xs","variant":"light-primary"},on:{"click":function($event){_vm.modal = true}}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Design/Edit.svg"}})],1)]),_c('b-modal',{attrs:{"title":_vm.$t('common.edit'),"size":"md","no-close-on-esc":"","no-close-on-backdrop":""},on:{"close":_vm.onClose},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(!_vm.loading)?_c('form-wrapper',{staticClass:"av-tooltip tooltip-right-top",attrs:{"validator":_vm.$v.form}},[_c('b-row',[_c('form-group',{attrs:{"name":"firstName","xs":"12","label":_vm.$t('project.firstName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}},'b-input',attrs,false),listeners))}}],null,false,1112316224)})],1),_c('b-row',[_c('form-group',{attrs:{"name":"lastName","xs":"12","label":_vm.$t('project.lastName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}},'b-input',attrs,false),listeners))}}],null,false,2668522352)})],1),_c('b-row',[_c('form-group',{attrs:{"name":"email","xs":"12","label":_vm.$t('project.email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},'b-input',attrs,false),listeners))}}],null,false,1528651985)})],1),_c('b-row',[_c('form-group',{attrs:{"name":"phone","xs":"12","label":_vm.$t('common.phone')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}},'b-input',attrs,false),listeners))}}],null,false,1938047041)})],1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('template',{slot:"modal-footer"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("common.cancel"))+" ")]),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":_vm.$v.$anyError || _vm.saved || _vm.submitting},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t("common.save"))+" "),(_vm.submitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }