var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('b-card',{attrs:{"title":_vm.$t('project.userList')}},[_c('b-table-simple',{attrs:{"borderless":"","responsive":true,"table-class":"table-vertical-center"}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v(_vm._s(_vm.$t('project.user')))]),_c('b-th',[_vm._v(_vm._s(_vm.$t('project.email')))]),_c('b-th',[_vm._v(_vm._s(_vm.$t('project.transaction')))])],1)],1),_c('b-tbody',[(!_vm.userLoading)?_vm._l((_vm.users),function(item,i){return _c('b-tr',{key:i},[_c('b-td',[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('b-td',[_vm._v(" "+_vm._s(item.email)+" ")]),_c('b-td',[_c('edit-user',{attrs:{"user-id":item.id},on:{"refreshList":_vm.getData}}),_c('b-button',{staticClass:"btn-icon",attrs:{"size":"xs","variant":"light-danger"},on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Trash.svg"}})],1)])],1)],1)}):_vm._e(),(_vm.users.length === 0 && !_vm.userLoading)?_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"colspan":"3"}},[_vm._v(_vm._s(_vm.$t('project.noRecord')))])]):_vm._e(),(_vm.userLoading)?_c('tr',{staticClass:"text-center"},[_c('td',{attrs:{"colspan":"3"}},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)]):_vm._e()],2)],1),(_vm.pageSize < _vm.totalRows)?_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":_vm.pageSize},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('form-wrapper',{attrs:{"validator":_vm.$v.createForm}},[_c('b-card',{attrs:{"title":_vm.$t('project.addUser')}},[_c('b-row',[_c('form-group',{attrs:{"name":"firstName","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.firstName')},model:{value:(_vm.createForm.firstName),callback:function ($$v) {_vm.$set(_vm.createForm, "firstName", $$v)},expression:"createForm.firstName"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"lastName","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.lastName')},model:{value:(_vm.createForm.lastName),callback:function ($$v) {_vm.$set(_vm.createForm, "lastName", $$v)},expression:"createForm.lastName"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"email","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.email')},model:{value:(_vm.createForm.email),callback:function ($$v) {_vm.$set(_vm.createForm, "email", $$v)},expression:"createForm.email"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"phone","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('0(###) ### ####'),expression:"'0(###) ### ####'"}],attrs:{"placeholder":_vm.$t('common.phone')},model:{value:(_vm.createForm.phone),callback:function ($$v) {_vm.$set(_vm.createForm, "phone", $$v)},expression:"createForm.phone"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"password","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.password'),"type":"password"},model:{value:(_vm.createForm.password),callback:function ($$v) {_vm.$set(_vm.createForm, "password", $$v)},expression:"createForm.password"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('form-group',{attrs:{"name":"rePassword","xs":"12","no-label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return _c('b-input',_vm._g(_vm._b({attrs:{"placeholder":_vm.$t('project.reNewPassword'),"type":"password"},model:{value:(_vm.createForm.rePassword),callback:function ($$v) {_vm.$set(_vm.createForm, "rePassword", $$v)},expression:"createForm.rePassword"}},'b-input',attrs,false),listeners))}}])})],1),_c('b-row',[_c('b-col',[_c('b-button',{on:{"click":_vm.addUser}},[_vm._v(_vm._s(_vm.$t('project.add')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }